<template>
    <div class="scoreContainer">
       
        <div style="margin-top: 20px;margin-left: 5%; width: 90%;height: 40px;float: left;">

            <span style="margin-right: 20px;">Rank By:</span>

            <el-radio v-model="rankType" label="rank">Date</el-radio>
            <el-radio v-model="rankType" label="school">PD Line</el-radio>
            <el-radio v-model="rankType" label="region">Application</el-radio>

        </div>
        <div style="clear: both;"></div>
        <div style="margin-top: 10px;margin-left: 5%; width: 90%;height: calc(100% - 130px);">

            <el-table style="width: 100%;height: 100%;" :data="projectList"  empty-text="No Data" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
                <el-table-column label="Date" width="180px" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.exam_end_time_show }}
                    </template>
                </el-table-column>
                <el-table-column label="PD Line" align="left">
                
                    <template slot-scope="scope">
                        {{  'PD Line' }}
                    </template>
                </el-table-column>      	

                <el-table-column  label="Project" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.project_name }}
                    </template>
                </el-table-column>

                <el-table-column  label="Application" width="100px" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.applicationNum }}
                    </template>
                </el-table-column>

                <el-table-column  label="Check In" width="100px" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.checkInNum }}
                    </template>
                </el-table-column>


                <el-table-column  label="Score"  align="center">
                    <template slot-scope="scope">
                        <el-button :class=" scope.row.project_score_show == 'show' ? 'btn-linkSelect' : 'btn-link' " @click="updateProjectSetting(scope.row._id.$id,'project_score_show','show')" type="primary" size="small">On</el-button>
                        <el-button :class=" scope.row.project_score_show == 'hide' ? 'btn-linkSelect' : 'btn-link' " @click="updateProjectSetting(scope.row._id.$id,'project_score_show','hide')" type="primary" size="small">Off</el-button>
                    </template>
                </el-table-column>

                <el-table-column  label="Award" align="center">
                    <template slot-scope="scope">
                        <el-button :class=" scope.row.project_award_show == 'show' ? 'btn-linkSelect' : 'btn-link' " @click="updateProjectSetting(scope.row._id.$id,'project_award_show','show')" type="primary" size="small">On</el-button>
                        <el-button :class=" scope.row.project_award_show == 'hide' ? 'btn-linkSelect' : 'btn-link' " @click="updateProjectSetting(scope.row._id.$id,'project_award_show','hide')" type="primary" size="small">Off</el-button>
                        
                    </template>
                </el-table-column>

                <el-table-column  label="Certifi" align="center">
                    <template slot-scope="scope">
                        <el-button :class=" scope.row.project_certifi_show == 'show' ? 'btn-linkSelect' : 'btn-link' " @click="updateProjectSetting(scope.row._id.$id,'project_certifi_show','show')" type="primary" size="small">On</el-button>
                        <el-button :class=" scope.row.project_certifi_show == 'hide' ? 'btn-linkSelect' : 'btn-link' " @click="updateProjectSetting(scope.row._id.$id,'project_certifi_show','hide')" type="primary" size="small">Off</el-button>
                    </template>
                </el-table-column>

                <el-table-column  label="Transcript" align="center">
                    <template slot-scope="scope">
                        <el-button :class=" scope.row.project_transcript_show == 'show' ? 'btn-linkSelect' : 'btn-link' " @click="updateProjectSetting(scope.row._id.$id,'project_transcript_show','show')" type="primary" size="small">On</el-button>
                        <el-button :class=" scope.row.project_transcript_show == 'hide' ? 'btn-linkSelect' : 'btn-link' " @click="updateProjectSetting(scope.row._id.$id,'project_transcript_show','hide')" type="primary" size="small">Off</el-button>
                    </template>
                </el-table-column>

            </el-table>
        </div>

    </div>
</template>

<script>
import {getCompleteExamProjectsV2,updateProjectScoreAwardSetting} from '../../api/index'
import {getUserId ,getProjectCode} from '../../utils/store'
export default {
    data(){
        return{
            projectList:[],
            searchKey:{
                'year':'',
                'pdline':'',
                'project_code':'',
            },
            rankType:''
        }
    },
    mounted(){
        this.getCompleteExamProjectsV2()
    },
    methods:{

        toApply(){

        },
        toClear(){
            this.searchKey.year = ''
            this.searchKey.pdline = ''
            this.searchKey.project_code = ''
        },



        handleEventName(event){
            let event_array = event.split(" ")
            return event_array[0]
        },
        getCompleteExamProjectsV2(){
            getCompleteExamProjectsV2().then(res=>{
                this.projectList = []
                if(res.data.code===0){
                    this.projectList = res.data.data
                }
            })
        },
        updateProjectSetting(project_id,type,select){

            console.log(type,select)

            updateProjectScoreAwardSetting(project_id,type,select).then(res=>{
                if(res.data.code===0){
                    this.projectList = []
                    this.getCompleteExamProjectsV2()
                    this.$message.success('Setting Successfully');
                }
            })

            

        }
    }
}
</script>

<style lang="less">
.scoreContainer{
    width: 100vw;
    height: calc(100vh - 80px);
}

.btn-linkSelect{
  background-color:  red;
  border-color: red;
}
</style>